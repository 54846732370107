@import url('https://fonts.googleapis.com/css?family=Raleway:300,400,500,700&display=swap');
@import url("https://unpkg.com/@tailwindcss/typography@0.2.x/dist/typography.min.css");
@import url("https://unpkg.com/tailwindcss@^1.0/dist/tailwind.min.css");



.Dashboard {
  padding-left: 10px;
  height: 100%;
  top: 0;
  left: 0;
}
 
.header {
    font-size: 2.5rem;
    margin-bottom: 1rem;
    text-align: center;
}
 
input {
    padding: 10px 3px;
    width: 100%;
}
 
.btn-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 1rem;
}
 
.btn {
    padding: 10px 1rem;
    background: #334;
    color: white;
    border-radius: 5px;
    cursor: pointer;
}
 
.todo-content {
    margin-top: 2rem;
}

.image {
  float: left;
  margin: 5px;
}

.para {
  text-align: justify;
  font-size: 25px;
}

.sec-title{
    position:relative;
    padding-bottom:40px;
}

.sec-title .title{
    position:relative;
    color:#bd5500;
    font-size:18px;
    font-weight:700;
    padding-right:50px;
    margin-bottom:15px;
    display:inline-block;
    text-transform:capitalize
}

.sec-title .title:before{
    position:absolute;
    content:'';
    right:0;
    bottom:7px;
    width:40px;
    height:1px;
    background-color:#bbb
}

.sec-title h2{
    position:relative;
    color:#252525;
    font-size:36px;
    font-weight:700;
    line-height:1.5em;
    display:block
}

.sec-title.light h2{
    color:#fff
}


.grid-cols-3 {
  grid-template-columns: repeat(1,minmax(0,1fr));
}

.navbar-nav .nav-link {
  padding-right: 0;
  padding-left: 10px;
}

.pagePagination li {
  display: inline-block;
  background-color: white;
}

.pagePagination button {
  display: inline-block;
}

.pagePagination {
  width: auto;
  display: inline-block;
  margin: 20px 0 0 0;
  padding: 0;
  list-style-type: none;
}

.pagePagination button {
  display: inline-block;
  padding: 0 20px;
  vertical-align: middle;
  line-height: 45px;
  height: 45px;
  box-sizing: border-box;
  border: 1px solid #dee2e6 !important;
  cursor: pointer;
  outline: none;
  font-size: 15px;
  margin: 0 0 0 -1px !important;
  font-weight: 300;
  transition: all 250ms ease-in;
}

.paginationContent {
  min-height: 100px;
  margin-top: 50px;
  font-size: 20px;
  color: #20232a;
}

.paginateButton {
  line-height: 42px;
}

.pagePagination button:hover {
  z-index: 2;
  color: white;
  text-decoration: none;
  background-color: #a89b8c;
  border-color: #dee2e6;
}

.paginateButton:disabled {
  pointer-events: none;
}

.pagePagination button.active {
  color: #fff;
  background-color: #bd5500;
  border: 1px solid #bd5500 !important;
}

.rounded {
  border-radius: var(--bs-border-radius)!important;
  width: 100%;
}

.offcanvas {
  position: fixed;
  bottom: 0;
  z-index: var(--bs-offcanvas-zindex);
  display: flex;
  flex-direction: column;
  max-width: 100%;
  color: var(--bs-offcanvas-color);
  visibility: hidden;
  background-color: #a89b8c !important;
  background-clip: padding-box;
  outline: 0;
  transition: transform .3s ease-in-out;
}

button, input, optgroup, select, textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
  border: 1px solid;
  padding: 5px;
  border-radius: 4px;
}

.note-content {
  margin-top: 2rem;
}

.card-text:last-child{
  padding: 10px;
}

.bg-red-700 {
background-color: rgba(148, 99, 59,var(--bg-opacity));
}

.navAuthButton{
  width: 50px;
  height: 50px;
}

.center {
  height: 100vh;
  display: flex;
}

.note {
  background-color: #fff;
  border: 1px solid #dadce0;
  border-radius: 8px;
  padding: 20px;
  cursor: default;
  -webkit-transition: all .2s ease-in-out;
  transition: all .2s ease-in-out;
  position: relative
}

.note:focus,
.note:hover {
  -webkit-box-shadow: 0 1px 2px 0 rgba(60, 64, 67, .3), 0 2px 6px 2px rgba(60, 64, 67, .15);
  box-shadow: 0 1px 2px 0 rgba(60, 64, 67, .3), 0 2px 6px 2px rgba(60, 64, 67, .15)
}

.note:focus svg,
.note:hover svg {
  opacity: .75
}

.note h1 {
  font-size: 18px
}

.note pre {
  font-family: Roboto, system, -apple-system, BlinkMacSystemFont, Helvetica Neue, Segoe UI, Arial, sans-serif;
  white-space: pre-wrap
}

.note svg {
  position: absolute;
  top: 12px;
  right: 12px;
  width: 25px;
  height: 25px;
  opacity: 0;
  -webkit-transition: all .2s ease-in-out;
  transition: all .2s ease-in-out;
  cursor: pointer
}

.note .empty {
  color: rgba(0, 0, 0, .5);
  font-size: 24px;
  font-family: Product Sans, system, -apple-system, BlinkMacSystemFont, Roboto, Helvetica Neue, Segoe UI, Arial, sans-serif
}

.notes {
  display: grid;
  grid-gap: 20px;
  grid-template-columns: repeat(auto-fit, minmax(270px, 1fr));
  position: relative;
  margin: 50px auto
}

.backdrop {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 9
}

.create-note {
  position: relative;
  width: 100%;
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
  background-color: #fff;
  border-radius: 8px;
  z-index: 10
}

.create-note,
.create-note button {
  -webkit-box-shadow: 0 1px 2px 0 rgba(60, 64, 67, .3), 0 2px 6px 2px rgba(60, 64, 67, .15);
  box-shadow: 0 1px 2px 0 rgba(60, 64, 67, .3), 0 2px 6px 2px rgba(60, 64, 67, .15)
}

.create-note button {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  position: absolute;
  right: 18px;
  bottom: -18px;
  background: #61DAFB;
  color: #fff;
  border: none;
  border-radius: 50%;
  width: 36px;
  height: 36px;
  font-size: 24px;
  line-height: 1;
  text-align: center
}

.create-note button span {
  margin-top: -3px
}

.edit-form {
  position: relative;
  width: 100%;
  max-width: 600px;
  background: #fff;
  padding: 20px 20px 8px;
  border-radius: 8px;
  -webkit-box-shadow: 0 1px 2px 0 rgba(60, 64, 67, .3), 0 2px 6px 2px rgba(60, 64, 67, .15);
  box-shadow: 0 1px 2px 0 rgba(60, 64, 67, .3), 0 2px 6px 2px rgba(60, 64, 67, .15);
  -webkit-transition: all .2s ease-in-out;
  transition: all .2s ease-in-out
}

.edit-form input {
  margin-bottom: 20px
}

.edit-form {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center
}

.edit-form .submit-button {
  height: 36px;
  padding: 8px 24px;
  border-radius: 4px;
  font-family: Product Sans, system, -apple-system, BlinkMacSystemFont, Roboto, Helvetica Neue, Segoe UI, Arial, sans-serif;
  font-weight: 700;
  font-size: 14px
}

.edit-form .submit-button:focus,
.edit-form .submit-button:hover {
  background-color: rgba(0, 0, 0, .08)
}

.edit-form .delete-button {
  width: 35px;
  padding: 8px;
  margin-left: -8px;
  opacity: .5
}

.edit-form .delete-button:focus,
.edit-form .delete-button:hover {
  opacity: 1
}

.pro-sidebar {
    width: 335px !important;
    display: inline-block;
    height: 100vh !important;
	  vertical-align: top;
    top: 0;
    bottom: 0 !important;
    position: fixed;
    background-color: darkslategrey !important;
}

.min-h {
  min-width: 75vh;
}

.pBlog {
  font-size: 10px;
  color: black;
  opacity: 75%;
  background-color: #f6ad55;
}

.profile{
  min-width: 15%;
  border: 1px solid #dcdcdc;
  padding: 20px;
  position: absolute;
  left: 0;
  z-index: 50;
  display: flex;
  flex-direction: column;
  height: 100vh;
  background-color: rgb(166, 118, 80);
}

.profile p {
  text-align: left;
}

.profile > span{
  color: blue;
  margin-top: 30px;
  cursor: pointer;
}

.auth{
  width: 90%;
  max-width: 400px;
  border: 1px solid #dcdcdc;
  border-radius: 4px;
  padding: 20px;
  margin:auto;
  text-align: center;
  border-bottom: 3px solid #94633b;
  box-shadow: 0px 0px 4px hsl(210, 12%, 75%);
}

.auth form{
  display: flex;
  flex-direction: column;
  gap: 15px;
  margin: 0 auto 10px auto;
}

.auth__success{
  padding: 10px 0;
  background-color: hsl(147, 79%, 87%);
  margin-bottom: 20px;
}

.auth__error{
  padding: 10px 0;
  background-color: hsl(0, 79%, 87%);
  margin-bottom: 20px;
}

.auth form > input, button{
  height: 40px;
  font-size: 16px;
  padding: 0 10px;
  border: solid 1px #dcdcdc;
  border-radius: 5px;
}

.verifyEmail {
  width: 90%;
  max-width: 500px;
  box-shadow: 0px 0px 4px hsl(210, 12%, 75%);
  text-align: center;
  padding: 40px 20px;
  background-color: hsl(210, 10%, 98%);
  border-bottom: 3px solid #94633b;
}

.border-green-400 {
  border-color: #94633b !important;
}

.verifyEmail h1{
  font-weight: 300;
  margin-top: 0;
}

.verifyEmail > p{
  line-height: 25px;
}

.verifyEmail > span{
  color: gray;
}

.verifyEmail > button {
  margin-top: 35px;
}

:root{
  --sidebar-flex:1.5;
  --condiv-flex:6;
  --fontsize-p:1.2rem;
  --fontsize-nav:1.6rem;
  --typing-font:3rem;
}
*
{
  margin:0;
  padding: 0;
}

html, body {
  margin: 0;
  height: 100%;

}

body{
  display: grid;
  grid-gap: 10px;
  grid-template-columns: 1fr;
  grid-template-areas: "main" "footer";
  grid-template-rows: 1fr 80px;
}

.content {
  flex: 1 0 auto;
}

.App {
  text-align: center;
  display: flex;
  width: 100vw;
  min-height: 100vh;
}

nav {
  margin: 0;
  width: 100%;
  height: 80px;
  background-color: black;
  padding:60px 30px 0px 30px ;
  flex:var(--sidebar-flex);
  justify-content: center;
  align-items: center;
  color: white;
  text-decoration: none;
}

.nav-link:hover {
  background-color: #bd5500;
  border-radius: 3px;
  box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
}

.aside{
  height: 100vh !important;
}

nav ul{
  font-size:var(--fontsize-nav) ;
  text-align: center;
  list-style-type: none;
  text-decoration: none;
  color: white !important;
}
nav ul li
{
  margin:40px;
}

a {
  color: white;
  text-decoration: none;
}

input,
textarea {
  margin-top: 5px;
}

.loginPage {
  width: 100vw;
  height: calc(100vh - 80px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.jumbotron-fluid {
  background-color: #bd5500 !important;
}

.footer {
  padding: 3.07rem 2.3rem 2.3rem;
  border-top: 3px solid #000;
  border-radius: 5px 5px 0 0;
  color: #e4e9f0;
  background: rgb(169 154 140);
  bottom: 0;
}

.fin-float{
  float: right;
  position: relative;
}

.site-page-header {
  border: 1px solid rgb(235, 237, 240);
}

.finner {
    border-top: 1px solid #000;
    padding-top: 1.53rem;
    margin-top: 0.76rem;
}

.article {
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  display: grid;
  place-items: center;
}

.header {
  font-size: 80px;
  color: white !important;
  text-align: center;
}

.card {
  margin-left: 10px;
  margin-right: 10px;
  padding: 15px;
  box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
  transition: all 0.3s cubic-bezier(.25,.8,.25,1);
  border-bottom: 3px solid #000;
}

.contactInput {
  box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
  transition: all 0.3s cubic-bezier(.25,.8,.25,1);
  border: 0.5px solid #000;
  border-radius: 4px;
  padding: 3px;
  min-width: 100%;
}

.condiv
{
  padding: 100px ;
  background-color: #f4f5f9;
  flex:var(--condiv-flex);
}

.subtopic
{
  margin:10px 10px 30px 10px !important;
}

.skills ul li
{
  list-style-type:none;
  margin:30px;
}

.social
{
  position: absolute;
  margin-top: 20px;
  display: flex;
  bottom: 60px;
}
.social i
{
  padding-right: 30px;
  font-size: 25px;
  color: #9a9b9c;
}
.social i:hover
{
  color: #ffffff;
}
.scard{
  position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    border: 1px solid rgba(0,0,0,.125);
    border-radius: 0.25rem;
    box-shadow: 0 6px 10px rgb(0 0 0 / 8%), 0 0 6px rgb(0 0 0 / 5%);
    -webkit-transition: transform .3s cubic-bezier(.155,1.105,.295,1.12),box-shadow .3s,-webkit-transform .3s cubic-bezier(.155,1.105,.295,1.12);
    transition: transform .3s cubic-bezier(.155,1.105,.295,1.12),box-shadow .3s,-webkit-transform .3s cubic-bezier(.155,1.105,.295,1.12);
    padding: 14px 80px 18px 36px;
}

.container {
  width: 100%;
  margin-top: 90px;
  padding-bottom: 10px;
}

.post-container {
  width: 950px;
  margin: auto;
  margin-bottom: 90px;
}

.work {
  display: flex;
  flex-direction: row;
  grid-template-rows: auto;
  grid-row-end: auto;
  grid-auto-rows: auto;
  flex-wrap: wrap;
}

.noteListCard {
  border-radius: 4px;
  background-color: #fff;
  border-bottom: 3px solid #94633b;
  margin-top: 24px;
  height: auto;
  box-shadow: 0px 0px 4px hsl(210, 12%, 75%);
}

.noteListCard img {
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
}

.noteListCard-header {
    color: #1b242f;
    font-size: 150%;
    background-color: inherit;
    border-bottom: 1px solid hsla(0,0%,62.7%,.2);
    position: relative;
    padding: 16px 24px;
}

.noteListCard-content {
  padding: 16px;
  color: #1b242f;
  height: auto;
}

.noteListCard-action {
  color: #fff;
  background-color: #04c2c9;
  font-size: 15px;
  padding: 16px 24px;
}

.portfolioCard {
  border-radius: 4px;
  background-color: #fff;
  border-bottom: 3px solid #94633b;
  margin-top: 24px;
  height: auto;
  box-shadow: 0px 0px 4px hsl(210, 12%, 75%);
}

.portfolioCard img {
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
}

.portfolioCard-header {
    color: #1b242f;
    font-size: 150%;
    background-color: inherit;
    border-bottom: 1px solid hsla(0,0%,62.7%,.2);
    position: relative;
    padding: 16px 24px;
}

.portfolioCard-content {
  padding: 16px;
  color: #1b242f;
  height: auto;
}

.portfolioCard-action {
  color: #fff;
  background-color: #04c2c9;
  font-size: 15px;
  padding: 16px 24px;
}

.bigContainer{
  min-height: 100vh;
}

@keyframes fadeIn { from { opacity:0; } to { opacity:0.9; } }
@keyframes fadeOut { from { opactiy:0.9; } to { opacity:0; } }

.fade-in {
    opacity:0;
    animation:fadeIn ease-in 1;
    animation-fill-mode:forwards;
    animation-duration: 0.1s;
}
.noteContent{
  margin: 0;
}
.closebtn {
    color: #414141;
    float: right;
    line-height: 1;
    cursor: pointer;
    font-size: 23px;
    position: absolute;
    top: 50%;
    right:  15px;
    transform: translateY(-50%);
}
.closebtn:hover {
    color: #AC2500;
}
.ui-state-highlight {
  height:  48px;
  background-color:  rgba(255,255,255,0.70);
  border-radius:  5px;
  border:  none;
  margin-bottom:  8px;
}

.login {
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
}

.login__container {
  display: flex;
  flex-direction: column;
  text-align: center;
  background-color: #dcdcdc;
  padding: 30px;
}

.login__textBox {
  padding: 10px;
  font-size: 18px;
  margin-bottom: 10px;
}

.login__btn {
  padding: 10px;
  font-size: 18px;
  margin-bottom: 10px;
  border: none;
  color: white;
  background-color: black;
}

.login__google {
  background-color: #4285f4;
}

.login div {
  margin-top: 7px;
}

.register {
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
}

.register__container {
  display: flex;
  flex-direction: column;
  text-align: center;
  background-color: #dcdcdc;
  padding: 30px;
}

.register__textBox {
  padding: 10px;
  font-size: 18px;
  margin-bottom: 10px;
}

.register__btn {
  padding: 10px;
  font-size: 18px;
  margin-bottom: 10px;
  border: none;
  color: white;
  background-color: black;
}

.register__google {
  background-color: #4285f4;
}

.register div {
  margin-top: 7px;
}

.reset {
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
}

.reset__container {
  display: flex;
  flex-direction: column;
  text-align: center;
  background-color: #dcdcdc;
  padding: 30px;
}

.reset__textBox {
  padding: 10px;
  font-size: 18px;
  margin-bottom: 10px;
}

.reset__btn {
  padding: 10px;
  font-size: 18px;
  margin-bottom: 10px;
  border: none;
  color: white;
  background-color: black;
}

.reset div {
  margin-top: 7px;
}

.border-green-400 {
  background-color: rgb(148, 99, 59) !important;
}

.addTask{
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 10px;
  z-index: 100;
}

.addTask input{
  height: 40px;
  border: unset;
  font-size: 18px;
  box-sizing: border-box;
  -webkit-box-sizing:border-box;
  -moz-box-sizing: border-box;
  border-bottom: 1.5px solid hsl(210, 10%, 75%);
  padding-left: 5px;
}

.addTask input:focus{
  outline: none;
}

.addTask button {
  height: 40px;
  border: none;
  color: hsl(210, 12%, 15%);
  background-color: #bd5500;
}

.addTask textarea {
  height: 150px;
  border-color: hsl(210, 10%, 75%);
  padding: 5px;
  font-size: 18px;
}

.addTask textarea:focus { 
  outline: none;
}

.addTask button:hover {
  background-color: black;
  color: #bd5500;
}

.addTask button:active {
  background-color: hsl(210, 12%, 15%);
}

.editTask{
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 10px;
  z-index: 100;
}

.editTask input{
  height: 40px;
  border: unset;
  font-size: 18px;
  box-sizing: border-box;
  -webkit-box-sizing:border-box;
  -moz-box-sizing: border-box;
  border-bottom: 1.5px solid hsl(210, 10%, 75%);
  padding-left: 5px;
}

.editTask input:focus{
  outline: none;
}

.editTask button {
  height: 40px;
  border: none;
  color: white;
  background-color: hsl(210, 12%, 15%);
}

.editTask textarea {
  height: 150px;
  border-color: hsl(210, 10%, 75%);
  padding: 5px;
  font-size: 18px;
}

.editTask textarea:focus { 
  outline: none;
}

.editTask button:hover {
  background-color: hsl(210, 12%, 25%);
}

.editTask button:active {
  background-color: hsl(210, 12%, 15%);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

button{
  cursor: pointer;
}

.task{
  width: 100%;
  padding: 15px 20px;
  border: 1px solid #bd5500;
  background-color: #e6e6e6;
  max-width: 550px;
  border-radius:3px;
  display: flex;
  gap: 20px;
  align-items: center;
  box-sizing: border-box;
  flex-wrap: wrap;
  box-shadow: 0px 0px 4px hsl(210deg 12% 75%);
}

.task--borderColor{
  border-color: green;
  box-shadow: 0 6px 18px -9px hsl(210, 15%, 65%);
}

.task__body {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  flex: 1 1 200px;
}

.task__body p{
  margin: 10px 0 0 0;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical; 
}

.task__buttons{
  display: flex;
  justify-content: space-between;
  margin-top: 30px;
  align-items: center;
}

.task__buttons > button {
  background-color: hsl(210, 70%, 60%);
  padding: 5px 10px;
  font-size: 16px;
  border: none;
  border-radius: 4px;
  color: white;
}

.task__buttons > button:hover {
  background-color: hsl(210, 70%, 50%);
}

.task__buttons > button:active{
  background-color: hsl(210, 70%, 60%);
}

.task__deleteNedit button {
  margin-right: 20px;
  background-color: transparent;
  border: none;
  font-size: 16px;
  color: hsl(210, 10%, 55%);
}

.task__editButton:hover {
  color: hsl(210, 20%, 25%);
  background-color: #6c757d !important;
  border-radius: 3px !important;
  color: white !important;
  border: .5px solid #6c757d !important;
}

.task__editButton:active {
  color: hsl(210, 10%, 55%);
  background-color: #6c757d !important;
  border-radius: 3px !important;
  color: white !important;
  border: .5px solid #6c757d !important;
}

.task__deleteNedit .task__deleteButton {
  color: hsl(0, 80%, 80%);
}

.task__deleteButton:hover {
  color: hsl(0, 70%, 70%);
  background-color: black;
  border-radius: 3px;
  border: .5px solid black;
}

.task__deleteButton:active {
  color: hsl(0, 80%, 80%);
  background-color: black;
  color: white;
  border-radius: 3px;
  border: .5px solid black;
}

.taskItem{
  margin-top: 20px;
}

.taskItem h2{
  font-weight: 400;
  text-transform: uppercase;
}

.taskItem p{
  margin-top: 10px;
  font-size: 18px;
  max-height: 300px;
  line-height: 25px;
  overflow-y: auto;
  color: hsl(210, 20%, 25%);
}

.taskManager > header{
  margin-top: 50px;
  padding: 20px;
  background-color: #95633b;
  color: white;
  font-size: 28px;
  font-family: 'Hanalei Fill', cursive;
}

.taskManager__container {
  width: 95%;
  margin: 5px auto 0 auto;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.taskManager__container > button {
  border-radius: 5px;
  border: none;
  color: white;
  font-size: 18px;
  margin: 0 auto;
  background-color: #bd5500;
  border: solid 1px black;
  cursor: pointer;
}

.taskManager__container > button:hover{
  background-color: #95633b;
  border: solid 1px white;
}

.taskManager__container > button:active{
  background-color: hsl(210, 12%, 15%);
}

.taskManager__tasks{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 5px;
  margin-top: 20px;
}

.addForm {
  width: auto;
  height: 550px;
  background: #e6e6e6;
  border-radius: 8px;
  padding: 20px 30px;
  max-width: calc(100vw - 40px);
  box-sizing: border-box;
  font-family: 'Montserrat',sans-serif;
}

.ck-editor__editable_inline {
  min-height: 200px;
}

.modal-header {
  background-color: #bd5500 !important;
  color: white !important;
}

.modal-footer{
  background-color: #94633b !important;
}

.modal-content {
  background-color:#e6e6e6 !important;
}